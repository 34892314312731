import React from 'react';
import { Link } from 'gatsby';
import {
  Navlist,
  NavlistItem,
} from '.././components/Navigation/styledNavigation';

export const Data = [
  {
    id: 1,
    text: 'Tech skills',
    url: '#skills',
  },
  {
    id: 2,
    text: 'Info',
    url: '#about',
  },
  {
    id: 3,
    text: '@me',
    url: '#contact',
  },
];

const PageLinks = () => {
  const tempLinks = Data.map((link) => {
    return (
      <NavlistItem key={link.id}>
        <Link to={link.url}>{link.text}</Link>
      </NavlistItem>
    );
  });

  return <Navlist>{tempLinks}</Navlist>;
};

export default PageLinks;
