import { Link } from 'gatsby';
import styled, { keyframes } from 'styled-components';
import heroImg from '../../assets/hero_img.jpg';
import '../../style//css/typography.css';
import { Color } from '../../style/styledConstants';

const smoothup = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0px 0px 0px #000000;
  }
  

  50% {
    transform: scale(1.1);
    box-shadow: 1px 2px 0px #000000;  
  }

  100% {
    transform: scale(1.1);
    box-shadow: 1px 2px 0px #000000;
  }
`;

const slideinLeft = keyframes`
    0% { 
      margin-left: -800px;
    }
    20% { 
      margin-left: -700px;
    }
    35% {
      margin-left: -600px;
    }
    80% {
      margin-left: 20px;
    }
    100% { 
      margin-left:0px;
    }
`;
const slideinRight = keyframes`
    0% { 
      margin-right: -800px;
    }
    20% { 
      margin-right: -700px;
    }
    35% {
      margin-right: -600px;
    }
    80% {
      margin-right: 20px;
    }
    100% { 
      margin-right:0px;
    }
`;

export const Nav = styled.nav`
  width: 100%;
  height: 10%;
  display: flex;

  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem 0.5rem;
  overflow: hidden;

  @media (min-width: 200px) and (max-width: 500px) {
    display: none;
  }
`;
export const Navlist = styled.ul`
  display: flex;
  /* justify-content: end; */
  align-items: center;
  /* flex-direction: flex-end; */
  animation: ${slideinRight} 1s forwards;
`;

export const NavlistItem = styled.li`
  list-style: none;
  padding: 0 1.5rem;

  a {
    /* color: ${Color.def}; */
    color: ${Color.dark_pink};
    font-family: 'Saira Condensed', sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 1.3rem;
    line-height: 1.3;
    text-decoration: none;
    transition: 0.1s ease-out;
    text-decoration-line: line-through;
    filter: grayscale(100%);
  }
  &:last-child a {
    color: ${Color.dark_pink};
    font-style: normal;
    font-weight: 600;
    filter: grayscale(100%);
  }
  &:last-child a:hover {
    color: ${Color.def};
    filter: grayscale(100%);
  }
  a:hover {
    color: ${Color.dark_pink};
    /* font-size: 1.7rem; */
    filter: grayscale(100%);
  }
`;

export const Paragraph = styled.p`
  font-family: 'Open Sans', 'Gill Sans MT';
  font-style: normal;
  color: ${Color.def};
  line-height: 1.3rem;
  font-weight: 200;
  font-size: 1rem;
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
  margin: 0 auto 0 0;
  overflow: hidden;
  animation: ${slideinLeft} 1s forwards;
`;

export const Logo = styled(Link)`
  border-radius: 50%;
  margin: 0.2rem 0;
  /* font-size: 1.2rem;
  text-align: center;
  color: white; */
  shape-outside: circle(50% at 50% 50%);
  &:hover {
    cursor: pointer;
    animation: ${smoothup} 2s forwards;
  }
`;

export const HeroImageNav = styled.div`
  background: center / contain no-repeat url(${heroImg});
  background-size: cover;
  box-shadow: 0px 2px 2px #000000;
  border-radius: 50%;
  height: 65px;
  width: 65px;
`;
