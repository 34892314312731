import * as React from 'react';
import Typewriter from 'typewriter-effect';
import const_img from '../../assets/constImg_LanArea.png';
import underConst from '../../assets/under-construction.png';
import { Footer, HeroCard, HeroLeft, HeroRight } from './styledHero';

const Hero = () => {
  return (
    <HeroCard>
      <HeroLeft>
        <h3 className='hero-name'>
          Hello<span>I</span>'m Siraj<span>Taeb</span>
        </h3>
        <p className='hero-job-title'>
          <Typewriter
            className='moving-text'
            onInit={(typewriter) => {
              // delay and other effects
              typewriter
                .typeString(' Software & Web Developer ...')
                .pauseFor(2500)
                .deleteChars(30)
                .pauseFor(600)
                .typeString(' UI/UX Designer ...')
                .pauseFor(1000)
                .start();
            }}
            options={{
              autoStart: true,
              loop: true,
            }}
          />
        </p>
      </HeroLeft>
      <HeroRight>
        <img src={const_img} alt='Deco Image' />
      </HeroRight>
      <Footer>
        <img src={underConst} alt='Website under Construction' />
      </Footer>
    </HeroCard>
  );
};

export default Hero;
