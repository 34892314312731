import styled, { keyframes } from 'styled-components';
import { Color } from '../../style/styledConstants';

const smoothUp = keyframes`
  0% {
    transform:scale(.8);
    opacity:0;
  }

  100% {
    transform:scale(1);
    opacity: 1;
  }

`;

export const HeroCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'heroLeft heroRight'
    'footer footer';

  align-items: center;
  justify-items: center;
  height: 90%;
  padding-top: 5rem;

  @media (max-width: 1440px) {
  }
  @media (max-width: 1200px) {
  }
  @media (max-width: 1024px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'heroLeft '
      'heroRight'
      'footer ';
    align-items: center;
    justify-items: center;
  }
  @media (max-width: 320px) {
  }
`;

export const HeroRight = styled.div`
  grid-area: heroRight;
  width: max-content;
  height: fit-content;
  img {
    height: fit-content;
    width: 18rem;
    -webkit-filter: drop-shadow(5px 5px 5px #222222);
    filter: drop-shadow(0px 10px 10px ${Color.dark});
    animation: ${smoothUp} 1.8s ease;
    -webkit-animation: ${smoothUp} 1.8s ease;
  }

  @media (max-width: 1440px) {
  }
  @media (max-width: 1250px) {
    img {
      width: 18rem;
    }
  }
  @media (max-width: 989px) {
    img {
      width: 16rem;
    }
  }
  @media (max-width: 768px) {
    img {
      width: 15rem;
    }
  }
  @media (max-width: 500px) {
    img {
      width: 10rem;
    }
  }
  @media (max-width: 320px) {
  }
`;

export const HeroLeft = styled.div`
  grid-area: heroLeft;
  display: flex;
  margin-left: 5rem;
  flex-direction: column;

  .hero-name {
    font-family: 'Righteous', cursive;
    font-weight: 400;
    font-size: 3rem;
    line-height: 5rem;
    color: ${Color.def};

    span:first-child {
      margin-left: 0.5rem;
      color: #eb3473;
    }

    span:nth-child(2) {
      position: relative;
      margin-left: 0.5rem;

      &:before {
        content: '(っ◔◡◔)っ';
        min-width: 100px;
        position: absolute;
        right: -2.3rem;
        top: -2rem;
        font-size: 1.3rem;
        line-height: 3rem;
        color: ${Color.def};
      }
    }
  }

  .hero-job-title {
    font-family: 'Courier New', sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    margin: 0 auto 0 1.7rem;
    color: ${Color.def};
  }

  @media (max-width: 1440px) {
    /* .hero-name {
      font-size: 3.2rem;
      line-height: 4rem;

      span:nth-child(2) {
        &:before {
          font-size: 1rem;
        }
      }
    }

    .hero-job-title {
      font-size: 1.2rem;
    } */
  }
  @media (max-width: 1250px) {
    .hero-name {
      font-size: 2.3rem;
      line-height: 3.5rem;

      span:nth-child(2) {
        &:before {
          font-size: 1rem;
        }
      }
    }

    .hero-job-title {
      font-size: 1rem;
    }
  }
  @media (max-width: 989px) {
    .hero-name {
      font-size: 2rem;
      line-height: 3.5rem;

      span:nth-child(2) {
        &:before {
          right: -3.5rem;
          font-size: 0.8rem;
        }
      }
    }

    .hero-job-title {
      font-size: 0.7rem;
    }
  }
  @media (max-width: 768px) {
    margin-left: 2rem;
    .hero-name {
      font-size: 2.3rem;
      line-height: 3.5rem;

      span:nth-child(2) {
        &:before {
          display: none;
        }
      }
    }

    .hero-job-title {
      font-size: 0.7rem;
    }
  }
  @media (max-width: 500px) {
    text-align: center;
    margin-left: 0;
    .hero-name {
      font-size: 2.3rem;
      line-height: 3.5rem;

      span:nth-child(2) {
        &:before {
          display: none;
        }
      }
    }

    .hero-job-title {
      font-size: 0.7rem;
      display: none;
    }
  }
  @media (max-width: 320px) {
  }
`;

export const Footer = styled.footer`
  grid-area: footer;
  text-align: center;
  margin-left: 2rem;
  img {
    width: 28rem;
    height: fit-content;
    filter: grayscale(100%);
  }
  /* @media (min-width: 1440px) {
    img {
      width: 20rem;
    }
  }
  } */
  @media (max-width: 1440px) {
    img {
      width: 26rem;
    }
  }
  }
  @media (max-width: 1250px) {
    img {
      width: 24rem;
    }
  } 
  @media (max-width: 989px) {
    img {
      width: 18rem;
    }
  }
  @media (max-width: 768px) {
    img {
      width: 16rem;
    }
  }
  @media (max-width: 425px) {
    img {
      width: 15rem;
    }
  }
  @media (max-width: 320px) {
     img {
      width: 13rem;
    }
  }
`;
