import * as React from 'react';
import { ConstWrapper, Container } from '../../style/styledConstants';
import Hero from '../Hero/Hero';
import Navigation from '../Navigation/Navigation';

const LandArea = () => {
  return (
    <Container id='landarea' bg='transparent'>
      <ConstWrapper height='screen-all' bg='transparent'>
        <Navigation />
        <Hero />{' '}
      </ConstWrapper>{' '}
      {/* <Scroll
        href='#about'
        scrollDirection='down'
        lineColor={Color.dark_pink}
        scrollColor={Color.def}
      >
        <span class='link__arrow'>
          <span></span>
          <span></span>
        </span>{' '}
        <span class='link__line'></span>
        <span class='link__text'>Scroll down</span>
      </Scroll> */}
    </Container>
  );
};

export default LandArea;
