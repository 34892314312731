import { Link } from 'gatsby';
import styled, { css, keyframes } from 'styled-components';

export const Color = {
  def: '#FFFFFF ',
  dark: 'rgba(3, 3, 3, 1)',
  primary: '#67B5DF',
  secondary: '#e953b7',
  dark_pink: '#eb3473',
  //FONT COLOR
  font_dark: 'rgba(1, 31, 38, 1)',
};

export const Font = {};

export const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  desktopL: '1440px',
  largeScreen: '2560px',
};

export const Device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  desktopL: `(max-width: ${size.desktopL})`,
  largeScreen: `(max-width: ${size.largeScreen})`,
};

// export const Device = {
//   mobileS: `(min-width: ${size.mobileS})`,
//   mobileM: `(min-width: ${size.mobileM})`,
//   mobileL: `(min-width: ${size.mobileL})`,
//   tablet: `(min-width: ${size.tablet})`,
//   laptop: `(min-width: ${size.laptop})`,
//   desktopL: `(min-width: ${size.desktopL})`,
//   largeScreen: `(min-width: ${size.largeScreen})`,
// };

const animationLine = keyframes`
  
  0% {
    left: 80px;
  }
  100% {
    left: 0; 
  }
`;

const animationArrow = keyframes`

  0% {
    width: 0;
  }
  100% {
    width: 100%; 
  }
`;

export const Container = styled.div`
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.bg};
`;

export const ConstWrapper = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: 0 auto auto auto;
  background-color: ${(props) => props.bg};

  ${({ height }) => {
    switch (height) {
      case 'screen-all':
        return css`
          height: 100vh;
        `;

      default:
        return css`
          height: auto;
        `;
    }
  }}
`;

export const Scroll = styled(Link)`
  position: absolute;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;

  ${({ scrollDirection }) => {
    switch (scrollDirection) {
      case 'up':
        return css`
          right: -40px;
          top: 25%;
          transform: rotate(90deg);
        `;
      case 'down':
        return css`
          right: -50px;
          bottom: 25%;
          transform: rotate(-90deg);
        `;
      default:
        return css`
          margin-top: 1rem;
          padding-top: 1rem;
        `;
    }
  }}

  z-index: 10;

  .link__arrow {
    display: inline-flex;
  }
  .link__arrow span {
    position: relative;
    width: 14px;
    height: 2px;
    border-radius: 2px;
    overflow: hidden;
    background: #bebdbe;
    z-index: 2;
  }
  .link__arrow span:nth-child(1) {
    transform-origin: left bottom;
    transform: rotate(45deg) translate3d(8px, -10px, 0);
  }
  .link__arrow span:nth-child(2) {
    transform-origin: left bottom;
    transform: rotate(-45deg);
  }
  .link__arrow span:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 0;
    height: 2px;
    background: ${(props) => props.lineColor};
  }
  .link__line {
    position: relative;
    margin-left: -14px;
    margin-right: 30px;
    width: 80px;
    height: 2px;
    background: #bebdbe;
    overflow: hidden;
    z-index: 1;
  }
  .link__line:after {
    content: '';
    display: block;
    position: absolute;
    left: 80px;
    width: 120px;
    height: 2px;
    background: ${(props) => props.lineColor};
  }
  .link__text {
    color: #4a4a4a;
    font-family: 'Oswald', sans-serif;
    font-size: 18px;
  }
  &:hover .link__text {
    color: ${(props) => props.scrollColor};
  }
  &:hover .link__line:after {
    animation: ${animationLine} 0.6s forwards;
  }
  &:hover .link__arrow span:after {
    animation: ${animationArrow} 0.6s forwards;
    animation-delay: 0.3s;
  }

  @media ${Device.mobileS}, ${Device.mobileL}, ${Device.tablet} {
    display: none;
  }
`;
