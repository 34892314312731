import * as React from 'react';
import LandArea from '../components/LandArea/LandArea';
import Layout from '../components/Layout/Layout';

const index = () => {
  return (
    <Layout>
      <LandArea />
      {/* <Contact /> */}
    </Layout>
  );
};
export default index;
