import * as React from 'react';

import PageLinks from '../../constants/links';
import {
  LogoWrapper,
  HeroImageNav,
  Nav,
  Logo,
  Paragraph,
} from './styledNavigation';

const Navigation = () => {
  return (
    <Nav>
      {/* <LogoWrapper> */}
      {/* <Logo to={'/'}> */}
      {/* <HeroImageNav alt='hero' /> */}
      {/* (っ◔◡◔)っ ♥ */}
      {/* </Logo>
        <Paragraph>Welcome to my Portfolio</Paragraph>
      </LogoWrapper> */}
      <PageLinks />
    </Nav>
  );
};

export default Navigation;
